/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderTypeDto } from '../models/order-type-dto';
import { OrderTypesRequest } from '../models/order-types-request';

@Injectable({
  providedIn: 'root',
})
export class OrderTypeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation checkAvailability
   */
  static readonly CheckAvailabilityPath = '/order-types/available';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAvailability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAvailability$Response(params: {
    context?: HttpContext
    body: OrderTypesRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OrderTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderTypeControllerService.CheckAvailabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkAvailability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAvailability(params: {
    context?: HttpContext
    body: OrderTypesRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OrderTypeDto>> {

    return this.checkAvailability$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OrderTypeDto>>) => r.body as Array<OrderTypeDto>)
    );
  }

}
