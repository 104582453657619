/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SsaRequestDto } from '../models/ssa-request-dto';

@Injectable({
  providedIn: 'root',
})
export class SsaRequestControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getRequestsForPatient
   */
  static readonly GetRequestsForPatientPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestsForPatient()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestsForPatient$Response(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<SsaRequestDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SsaRequestControllerService.GetRequestsForPatientPath, 'get');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SsaRequestDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestsForPatient$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestsForPatient(params: {
    patientGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<SsaRequestDto>> {

    return this.getRequestsForPatient$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<SsaRequestDto>>) => r.body as Array<SsaRequestDto>)
    );
  }

  /**
   * Path part for operation updateRequest
   */
  static readonly UpdateRequestPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRequest$Response(params: {
    patientGuid: string;
    files?: Array<Blob>;
    context?: HttpContext
    body?: {
'request': SsaRequestDto;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SsaRequestControllerService.UpdateRequestPath, 'put');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
      rb.query('files', params.files, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRequest(params: {
    patientGuid: string;
    files?: Array<Blob>;
    context?: HttpContext
    body?: {
'request': SsaRequestDto;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createRequest
   */
  static readonly CreateRequestPath = '/ssa-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest$Response(params: {
    patientGuid: string;
    files?: Array<Blob>;
    context?: HttpContext
    body?: {
'request': SsaRequestDto;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SsaRequestControllerService.CreateRequestPath, 'post');
    if (params) {
      rb.query('patientGuid', params.patientGuid, {});
      rb.query('files', params.files, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest(params: {
    patientGuid: string;
    files?: Array<Blob>;
    context?: HttpContext
    body?: {
'request': SsaRequestDto;
}
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.createRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getRequest
   */
  static readonly GetRequestPath = '/ssa-requests/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequest$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<SsaRequestDto>> {

    const rb = new RequestBuilder(this.rootUrl, SsaRequestControllerService.GetRequestPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SsaRequestDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequest(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<SsaRequestDto> {

    return this.getRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<SsaRequestDto>) => r.body as SsaRequestDto)
    );
  }

  /**
   * Path part for operation cancelRequest
   */
  static readonly CancelRequestPath = '/ssa-requests/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelRequest$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SsaRequestControllerService.CancelRequestPath, 'delete');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelRequest(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.cancelRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
