/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AtOrder } from '../models/at-order';
import { BdpOrOrder } from '../models/bdp-or-order';
import { CctOrder } from '../models/cct-order';
import { CreateOrderResult } from '../models/create-order-result';
import { CtOrder } from '../models/ct-order';
import { DrOrder } from '../models/dr-order';
import { EWorkupOrder } from '../models/e-workup-order';
import { HeOrder } from '../models/he-order';
import { HrOrder } from '../models/hr-order';
import { HwOrder } from '../models/hw-order';
import { InventoryOrderDetailsRequest } from '../models/inventory-order-details-request';
import { InventorySerialNumberDetails } from '../models/inventory-serial-number-details';
import { KitOrderRequest } from '../models/kit-order-request';
import { OrOrder } from '../models/or-order';
import { OrderLineItemServiceCode } from '../models/order-line-item-service-code';
import { OrderRecipient } from '../models/order-recipient';
import { OrderRequested } from '../models/order-requested';
import { OrderTracking } from '../models/order-tracking';
import { PageInventoryOrderDetails } from '../models/page-inventory-order-details';
import { PbscOrder } from '../models/pbsc-order';
import { PostponeCancelRequest } from '../models/postpone-cancel-request';
import { PostponementCancellationInfo } from '../models/postponement-cancellation-info';
import { TransplantTimelineTaskRequest } from '../models/transplant-timeline-task-request';
import { UwOrder } from '../models/uw-order';
import { WbOrder } from '../models/wb-order';
import { WuOrder } from '../models/wu-order';

@Injectable({
  providedIn: 'root',
})
export class OrderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation loadPostponeCancelRequests
   */
  static readonly LoadPostponeCancelRequestsPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPostponeCancelRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequests$Response(params: {
    orderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<PostponeCancelRequest>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadPostponeCancelRequestsPath, 'get');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostponeCancelRequest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadPostponeCancelRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequests(params: {
    orderGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<PostponeCancelRequest>> {

    return this.loadPostponeCancelRequests$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<PostponeCancelRequest>>) => r.body as Array<PostponeCancelRequest>)
    );
  }

  /**
   * Path part for operation updatePostponeCancelRequest
   */
  static readonly UpdatePostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePostponeCancelRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostponeCancelRequest$Response(params: {
    orderGuid: string;
    context?: HttpContext
    body: PostponeCancelRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.UpdatePostponeCancelRequestPath, 'put');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePostponeCancelRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePostponeCancelRequest(params: {
    orderGuid: string;
    context?: HttpContext
    body: PostponeCancelRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<number> {

    return this.updatePostponeCancelRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation addPostponeCancelRequest
   */
  static readonly AddPostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPostponeCancelRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPostponeCancelRequest$Response(params: {
    orderGuid: string;
    context?: HttpContext
    body: PostponeCancelRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.AddPostponeCancelRequestPath, 'post');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addPostponeCancelRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPostponeCancelRequest(params: {
    orderGuid: string;
    context?: HttpContext
    body: PostponeCancelRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<number> {

    return this.addPostponeCancelRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation loadOrderTrackings1
   */
  static readonly LoadOrderTrackings1Path = '/orders/{guid}/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrderTrackings1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrderTrackings1$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadOrderTrackings1Path, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: Array<OrderTracking>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadOrderTrackings1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrderTrackings1(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: Array<OrderTracking>;
}> {

    return this.loadOrderTrackings1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>) => r.body as {
[key: string]: Array<OrderTracking>;
})
    );
  }

  /**
   * Path part for operation updateOrderTracking
   */
  static readonly UpdateOrderTrackingPath = '/orders/{guid}/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrderTracking()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderTracking$Response(params: {
    guid: string;
    context?: HttpContext
    body: OrderTracking
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.UpdateOrderTrackingPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrderTracking$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrderTracking(params: {
    guid: string;
    context?: HttpContext
    body: OrderTracking
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<number> {

    return this.updateOrderTracking$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation updateOrder
   */
  static readonly UpdateOrderPath = '/orders/{guid}/recipient';

  /**
   * This method changes the infusion date of order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder$Response(params: {
    guid: string;
    context?: HttpContext
    body: OrderRecipient
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.UpdateOrderPath, 'put');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method changes the infusion date of order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOrder(params: {
    guid: string;
    context?: HttpContext
    body: OrderRecipient
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
}> {

    return this.updateOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation markInventoryOrderRecipientAsTransferred
   */
  static readonly MarkInventoryOrderRecipientAsTransferredPath = '/orders/inventory/mark-patient-as-transferred';

  /**
   * This method checks if a patient has inventory orders and if yes, updates transferred index field to Y
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markInventoryOrderRecipientAsTransferred()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderRecipientAsTransferred$Response(params: {
    rcpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.MarkInventoryOrderRecipientAsTransferredPath, 'put');
    if (params) {
      rb.query('rcpGuid', params.rcpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method checks if a patient has inventory orders and if yes, updates transferred index field to Y
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markInventoryOrderRecipientAsTransferred$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderRecipientAsTransferred(params: {
    rcpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.markInventoryOrderRecipientAsTransferred$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation markInventoryOrderAsUsed
   */
  static readonly MarkInventoryOrderAsUsedPath = '/orders/inventory/mark-as-used';

  /**
   * Marks Inventory Order Serial Number as used
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markInventoryOrderAsUsed()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderAsUsed$Response(params: {
    serialNumber: string;
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.MarkInventoryOrderAsUsedPath, 'put');
    if (params) {
      rb.query('serialNumber', params.serialNumber, {});
      rb.query('bpGuid', params.bpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Marks Inventory Order Serial Number as used
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markInventoryOrderAsUsed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markInventoryOrderAsUsed(params: {
    serialNumber: string;
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.markInventoryOrderAsUsed$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadAllOrders1
   */
  static readonly LoadAllOrders1Path = '/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadAllOrders1()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllOrders1$Response(params?: {
    recipientGuid?: string;
    sourceGuid?: string;
    orderStatus?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadAllOrders1Path, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('sourceGuid', params.sourceGuid, {});
      rb.query('orderStatus', params.orderStatus, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadAllOrders1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadAllOrders1(params?: {
    recipientGuid?: string;
    sourceGuid?: string;
    orderStatus?: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {

    return this.loadAllOrders1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>) => r.body as Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>)
    );
  }

  /**
   * Path part for operation placeOrder
   */
  static readonly PlaceOrderPath = '/orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `placeOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  placeOrder$Response(params: {
    context?: HttpContext
    body: Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CreateOrderResult>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.PlaceOrderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreateOrderResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `placeOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  placeOrder(params: {
    context?: HttpContext
    body: Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CreateOrderResult>> {

    return this.placeOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CreateOrderResult>>) => r.body as Array<CreateOrderResult>)
    );
  }

  /**
   * Path part for operation loadAllOrders
   */
  static readonly LoadAllOrdersPath = '/orders/{orderStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadAllOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadAllOrders$Response(params: {
    orderStatus: string;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadAllOrdersPath, 'post');
    if (params) {
      rb.path('orderStatus', params.orderStatus, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadAllOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadAllOrders(params: {
    orderStatus: string;
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {

    return this.loadAllOrders$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>) => r.body as Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>)
    );
  }

  /**
   * Path part for operation loadOrderTrackings
   */
  static readonly LoadOrderTrackingsPath = '/orders/trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrderTrackings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrderTrackings$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadOrderTrackingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: {
        [key: string]: Array<OrderTracking>;
        };
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadOrderTrackings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrderTrackings(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}> {

    return this.loadOrderTrackings$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: Array<OrderTracking>;
};
}>) => r.body as {
[key: string]: {
[key: string]: Array<OrderTracking>;
};
})
    );
  }

  /**
   * Path part for operation loadRecent
   */
  static readonly LoadRecentPath = '/orders/recent/transplant-timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadRecent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadRecent$Response(params: {
    context?: HttpContext
    body: TransplantTimelineTaskRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadRecentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadRecent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadRecent(params: {
    context?: HttpContext
    body: TransplantTimelineTaskRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: string;
}> {

    return this.loadRecent$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation getOrderTrackingServiceCodes
   */
  static readonly GetOrderTrackingServiceCodesPath = '/orders/order-line-items/service-codes';

  /**
   * Getting order line item's service codes by line item ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingServiceCodes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingServiceCodes$Response(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OrderLineItemServiceCode>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetOrderTrackingServiceCodesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderLineItemServiceCode>>;
      })
    );
  }

  /**
   * Getting order line item's service codes by line item ids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingServiceCodes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingServiceCodes(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OrderLineItemServiceCode>> {

    return this.getOrderTrackingServiceCodes$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OrderLineItemServiceCode>>) => r.body as Array<OrderLineItemServiceCode>)
    );
  }

  /**
   * Path part for operation loadOrders
   */
  static readonly LoadOrdersPath = '/orders/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrders$Response(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadOrdersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadOrders(params: {
    context?: HttpContext
    body: Array<string>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {

    return this.loadOrders$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>>) => r.body as Array<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>)
    );
  }

  /**
   * Path part for operation loadInventoryOrders
   */
  static readonly LoadInventoryOrdersPath = '/orders/inventory';

  /**
   * This method loads inventory orders of specific type based on bulk ind and bpGuids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadInventoryOrders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadInventoryOrders$Response(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: InventoryOrderDetailsRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageInventoryOrderDetails>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadInventoryOrdersPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageInventoryOrderDetails>;
      })
    );
  }

  /**
   * This method loads inventory orders of specific type based on bulk ind and bpGuids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadInventoryOrders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loadInventoryOrders(params: {

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
    body: InventoryOrderDetailsRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageInventoryOrderDetails> {

    return this.loadInventoryOrders$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageInventoryOrderDetails>) => r.body as PageInventoryOrderDetails)
    );
  }

  /**
   * Path part for operation requestKitOrder
   */
  static readonly RequestKitOrderPath = '/orders/inventory/swab-kit';

  /**
   * This method sends a request to common module to create a kit order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestKitOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestKitOrder$Response(params: {
    context?: HttpContext
    body: KitOrderRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.RequestKitOrderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method sends a request to common module to create a kit order
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestKitOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestKitOrder(params: {
    context?: HttpContext
    body: KitOrderRequest
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.requestKitOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadPostponeCancelRequest
   */
  static readonly LoadPostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadPostponeCancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequest$Response(params: {
    orderGuid: string;
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PostponeCancelRequest>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadPostponeCancelRequestPath, 'get');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PostponeCancelRequest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadPostponeCancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadPostponeCancelRequest(params: {
    orderGuid: string;
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PostponeCancelRequest> {

    return this.loadPostponeCancelRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PostponeCancelRequest>) => r.body as PostponeCancelRequest)
    );
  }

  /**
   * Path part for operation deletePostponeCancelRequest
   */
  static readonly DeletePostponeCancelRequestPath = '/orders/{orderGuid}/postpone-cancel-requests/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePostponeCancelRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostponeCancelRequest$Response(params: {
    orderGuid: string;
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.DeletePostponeCancelRequestPath, 'delete');
    if (params) {
      rb.path('orderGuid', params.orderGuid, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePostponeCancelRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePostponeCancelRequest(params: {
    orderGuid: string;
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deletePostponeCancelRequest$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation loadOrder
   */
  static readonly LoadOrderPath = '/orders/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loadOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrder$Response(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.LoadOrderPath, 'get');
    if (params) {
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loadOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  loadOrder(params: {
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)> {

    return this.loadOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<(AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder)>) => r.body as (AtOrder | BdpOrOrder | CctOrder | CtOrder | DrOrder | EWorkupOrder | HeOrder | HrOrder | HwOrder | OrOrder | PbscOrder | UwOrder | WbOrder | WuOrder))
    );
  }

  /**
   * Path part for operation findInProgressRequests
   */
  static readonly FindInProgressRequestsPath = '/orders/postponed-cancelled';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findInProgressRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  findInProgressRequests$Response(params: {
    activityCodes: Array<'TSKCPR' | 'TSKCCR' | 'TSKIPCR'>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<PostponementCancellationInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.FindInProgressRequestsPath, 'get');
    if (params) {
      rb.query('activityCodes', params.activityCodes, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PostponementCancellationInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findInProgressRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findInProgressRequests(params: {
    activityCodes: Array<'TSKCPR' | 'TSKCCR' | 'TSKIPCR'>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<PostponementCancellationInfo>> {

    return this.findInProgressRequests$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<PostponementCancellationInfo>>) => r.body as Array<PostponementCancellationInfo>)
    );
  }

  /**
   * Path part for operation getInventorySerialNumbers
   */
  static readonly GetInventorySerialNumbersPath = '/orders/inventory/serial-numbers';

  /**
   * This method retrieves inventory serial numbers by inventory order type (mandatory), id, serial number, business party GUID,  bulkInd and usedInd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventorySerialNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventorySerialNumbers$Response(params?: {
    inventoryType?: string;
    id?: number;
    serialNumber?: string;
    bpGuid?: string;
    bulk?: boolean;
    used?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<InventorySerialNumberDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetInventorySerialNumbersPath, 'get');
    if (params) {
      rb.query('inventoryType', params.inventoryType, {});
      rb.query('id', params.id, {});
      rb.query('serialNumber', params.serialNumber, {});
      rb.query('bpGuid', params.bpGuid, {});
      rb.query('bulk', params.bulk, {});
      rb.query('used', params.used, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InventorySerialNumberDetails>>;
      })
    );
  }

  /**
   * This method retrieves inventory serial numbers by inventory order type (mandatory), id, serial number, business party GUID,  bulkInd and usedInd
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getInventorySerialNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventorySerialNumbers(params?: {
    inventoryType?: string;
    id?: number;
    serialNumber?: string;
    bpGuid?: string;
    bulk?: boolean;
    used?: boolean;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<InventorySerialNumberDetails>> {

    return this.getInventorySerialNumbers$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<InventorySerialNumberDetails>>) => r.body as Array<InventorySerialNumberDetails>)
    );
  }

  /**
   * Path part for operation getDocumentRecipientGuid
   */
  static readonly GetDocumentRecipientGuidPath = '/orders/documents/getbp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentRecipientGuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentRecipientGuid$Response(params: {
    owcId: string;
    featureGuid: string;
    featureType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetDocumentRecipientGuidPath, 'get');
    if (params) {
      rb.query('owcId', params.owcId, {});
      rb.query('featureGuid', params.featureGuid, {});
      rb.query('featureType', params.featureType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentRecipientGuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentRecipientGuid(params: {
    owcId: string;
    featureGuid: string;
    featureType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<string> {

    return this.getDocumentRecipientGuid$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation wasCtRequestedForSourceAndBusinessParty
   */
  static readonly WasCtRequestedForSourceAndBusinessPartyPath = '/orders/ct-requested/{recipientGuid}/{sourceGuid}/{bpGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wasCtRequestedForSourceAndBusinessParty()` instead.
   *
   * This method doesn't expect any request body.
   */
  wasCtRequestedForSourceAndBusinessParty$Response(params: {
    recipientGuid: string;
    sourceGuid: string;
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<OrderRequested>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.WasCtRequestedForSourceAndBusinessPartyPath, 'get');
    if (params) {
      rb.path('recipientGuid', params.recipientGuid, {});
      rb.path('sourceGuid', params.sourceGuid, {});
      rb.path('bpGuid', params.bpGuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderRequested>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `wasCtRequestedForSourceAndBusinessParty$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wasCtRequestedForSourceAndBusinessParty(params: {
    recipientGuid: string;
    sourceGuid: string;
    bpGuid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<OrderRequested> {

    return this.wasCtRequestedForSourceAndBusinessParty$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<OrderRequested>) => r.body as OrderRequested)
    );
  }

  /**
   * Path part for operation findCtCompletedDonors
   */
  static readonly FindCtCompletedDonorsPath = '/orders/ct-completed-donors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findCtCompletedDonors()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCtCompletedDonors$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.FindCtCompletedDonorsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findCtCompletedDonors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findCtCompletedDonors(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<string>> {

    return this.findCtCompletedDonors$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
