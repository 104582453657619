/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CodeDescriptionDto } from '../models/code-description-dto';

@Injectable({
  providedIn: 'root',
})
export class NomenclaturesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation treatmentSubReasons
   */
  static readonly TreatmentSubReasonsPath = '/nomenclatures/treatment-sub-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSubReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasons$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.TreatmentSubReasonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treatmentSubReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasons(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.treatmentSubReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation treatmentSubReasonsByType
   */
  static readonly TreatmentSubReasonsByTypePath = '/nomenclatures/treatment-sub-reasons/{sourceType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSubReasonsByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasonsByType$Response(params: {
    sourceType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.TreatmentSubReasonsByTypePath, 'get');
    if (params) {
      rb.path('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treatmentSubReasonsByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSubReasonsByType(params: {
    sourceType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.treatmentSubReasonsByType$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation treatmentSecReasons
   */
  static readonly TreatmentSecReasonsPath = '/nomenclatures/treatment-sec-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `treatmentSecReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSecReasons$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.TreatmentSecReasonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `treatmentSecReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  treatmentSecReasons(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.treatmentSecReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation suggestedTypings
   */
  static readonly SuggestedTypingsPath = '/nomenclatures/suggested-typings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestedTypings()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedTypings$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.SuggestedTypingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestedTypings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestedTypings(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.suggestedTypings$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation requestStatuses
   */
  static readonly RequestStatusesPath = '/nomenclatures/request-statuses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestStatuses$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.RequestStatusesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestStatuses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestStatuses(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.requestStatuses$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation postponeCancelReasons
   */
  static readonly PostponeCancelReasonsPath = '/nomenclatures/postpone-cancel-reasons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postponeCancelReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasons$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.PostponeCancelReasonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postponeCancelReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasons(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.postponeCancelReasons$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation postponeCancelReasonsByType
   */
  static readonly PostponeCancelReasonsByTypePath = '/nomenclatures/postpone-cancel-reasons/{sourceType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postponeCancelReasonsByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasonsByType$Response(params: {
    sourceType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.PostponeCancelReasonsByTypePath, 'get');
    if (params) {
      rb.path('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postponeCancelReasonsByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postponeCancelReasonsByType(params: {
    sourceType: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.postponeCancelReasonsByType$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation otherSourceTypes
   */
  static readonly OtherSourceTypesPath = '/nomenclatures/other-sources-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `otherSourceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  otherSourceTypes$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.OtherSourceTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `otherSourceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  otherSourceTypes(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.otherSourceTypes$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation matchCategoryOptions
   */
  static readonly MatchCategoryOptionsPath = '/nomenclatures/match-category-options';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchCategoryOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchCategoryOptions$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.MatchCategoryOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `matchCategoryOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchCategoryOptions(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.matchCategoryOptions$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation labResultReports
   */
  static readonly LabResultReportsPath = '/nomenclatures/lab-result-reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labResultReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  labResultReports$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.LabResultReportsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `labResultReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labResultReports(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.labResultReports$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation hlaMismatches
   */
  static readonly HlaMismatchesPath = '/nomenclatures/hla-mismatches';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hlaMismatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  hlaMismatches$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.HlaMismatchesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `hlaMismatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hlaMismatches(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.hlaMismatches$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

  /**
   * Path part for operation donorMatchGrades
   */
  static readonly DonorMatchGradesPath = '/nomenclatures/donor-match-grades';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `donorMatchGrades()` instead.
   *
   * This method doesn't expect any request body.
   */
  donorMatchGrades$Response(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<CodeDescriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, NomenclaturesControllerService.DonorMatchGradesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CodeDescriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `donorMatchGrades$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  donorMatchGrades(params?: {
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<CodeDescriptionDto>> {

    return this.donorMatchGrades$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<CodeDescriptionDto>>) => r.body as Array<CodeDescriptionDto>)
    );
  }

}
