/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CordOpl } from '../models/cord-opl';
import { DonorOpl } from '../models/donor-opl';
import { OplReportsDto } from '../models/opl-reports-dto';
import { OrderPreviewListCopySourcesDto } from '../models/order-preview-list-copy-sources-dto';
import { OrderPreviewListDto } from '../models/order-preview-list-dto';
import { PageOplReportsDto } from '../models/page-opl-reports-dto';
import { SuggestedTypingsCriteria } from '../models/suggested-typings-criteria';

@Injectable({
  providedIn: 'root',
})
export class OrderPreviewListControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOpl
   */
  static readonly GetOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpl$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<OrderPreviewListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetOplPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderPreviewListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpl(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<OrderPreviewListDto> {

    return this.getOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<OrderPreviewListDto>) => r.body as OrderPreviewListDto)
    );
  }

  /**
   * Path part for operation updateOpl
   */
  static readonly UpdateOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOpl$Response(params: {
    id: number;
    context?: HttpContext
    body: OrderPreviewListDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.UpdateOplPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOpl(params: {
    id: number;
    context?: HttpContext
    body: OrderPreviewListDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteOpl
   */
  static readonly DeleteOplPath = '/opls/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOpl$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.DeleteOplPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOpl(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.deleteOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateOplSource
   */
  static readonly UpdateOplSourcePath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOplSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOplSource$Response(params: {
    id: number;
    context?: HttpContext
    body: (CordOpl | DonorOpl)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.UpdateOplSourcePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOplSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOplSource(params: {
    id: number;
    context?: HttpContext
    body: (CordOpl | DonorOpl)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.updateOplSource$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addSourceToOpl
   */
  static readonly AddSourceToOplPath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSourceToOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSourceToOpl$Response(params: {
    id: number;
    context?: HttpContext
    body: (CordOpl | DonorOpl)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.AddSourceToOplPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSourceToOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSourceToOpl(params: {
    id: number;
    context?: HttpContext
    body: (CordOpl | DonorOpl)
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.addSourceToOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeSourcesFromOpl
   */
  static readonly RemoveSourcesFromOplPath = '/opls/{id}/sources';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSourcesFromOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourcesFromOpl$Response(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.RemoveSourcesFromOplPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeSourcesFromOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourcesFromOpl(params: {
    id: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.removeSourcesFromOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation changeSourceOrder
   */
  static readonly ChangeSourceOrderPath = '/opls/{id}/sources/{guid}/order/{position}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeSourceOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSourceOrder$Response(params: {
    id: number;
    guid: string;
    position: number;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.ChangeSourceOrderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guid', params.guid, {});
      rb.path('position', params.position, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeSourceOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeSourceOrder(params: {
    id: number;
    guid: string;
    position: number;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.changeSourceOrder$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOpls
   */
  static readonly GetOplsPath = '/opls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOpls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpls$Response(params: {
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OrderPreviewListDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetOplsPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderPreviewListDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOpls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOpls(params: {
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OrderPreviewListDto>> {

    return this.getOpls$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OrderPreviewListDto>>) => r.body as Array<OrderPreviewListDto>)
    );
  }

  /**
   * Path part for operation createOpl
   */
  static readonly CreateOplPath = '/opls';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOpl$Response(params: {
    context?: HttpContext
    body: OrderPreviewListDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.CreateOplPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOpl(params: {
    context?: HttpContext
    body: OrderPreviewListDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: number;
}> {

    return this.createOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>) => r.body as {
[key: string]: number;
})
    );
  }

  /**
   * Path part for operation getDonorContactReport
   */
  static readonly GetDonorContactReportPath = '/opls/{oplId}/donor-contact-report';

  /**
   * Fetch donor contact report by opl id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorContactReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorContactReport$Response(params: {
    oplId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<PageOplReportsDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetDonorContactReportPath, 'post');
    if (params) {
      rb.path('oplId', params.oplId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageOplReportsDto>;
      })
    );
  }

  /**
   * Fetch donor contact report by opl id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorContactReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDonorContactReport(params: {
    oplId: number;

    /**
     * Zero-based page index (0..N)
     */
    page?: number;

    /**
     * The size of the page to be returned
     */
    size?: number;

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: Array<string>;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<PageOplReportsDto> {

    return this.getDonorContactReport$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<PageOplReportsDto>) => r.body as PageOplReportsDto)
    );
  }

  /**
   * Path part for operation saveOrUpdateDefaultOpl
   */
  static readonly SaveOrUpdateDefaultOplPath = '/opls/{id}/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOrUpdateDefaultOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveOrUpdateDefaultOpl$Response(params: {
    id: number;
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.SaveOrUpdateDefaultOplPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveOrUpdateDefaultOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveOrUpdateDefaultOpl(params: {
    id: number;
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.saveOrUpdateDefaultOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSuggestedTypings
   */
  static readonly GetSuggestedTypingsPath = '/opls/suggested-typings/{guid}';

  /**
   * Get suggested typings
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedTypings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTypings$Response(params: {
    guid: string;
    context?: HttpContext
    body: SuggestedTypingsCriteria
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<DonorOpl>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetSuggestedTypingsPath, 'post');
    if (params) {
      rb.path('guid', params.guid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DonorOpl>>;
      })
    );
  }

  /**
   * Get suggested typings
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuggestedTypings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSuggestedTypings(params: {
    guid: string;
    context?: HttpContext
    body: SuggestedTypingsCriteria
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<DonorOpl>> {

    return this.getSuggestedTypings$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<DonorOpl>>) => r.body as Array<DonorOpl>)
    );
  }

  /**
   * Path part for operation getDonorContactReportByReportIds
   */
  static readonly GetDonorContactReportByReportIdsPath = '/opls/donor-contact-report-by-ids';

  /**
   * Fetch donor contact reports by report ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDonorContactReportByReportIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorContactReportByReportIds$Response(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OplReportsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetDonorContactReportByReportIdsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OplReportsDto>>;
      })
    );
  }

  /**
   * Fetch donor contact reports by report ids
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDonorContactReportByReportIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDonorContactReportByReportIds(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OplReportsDto>> {

    return this.getDonorContactReportByReportIds$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OplReportsDto>>) => r.body as Array<OplReportsDto>)
    );
  }

  /**
   * Path part for operation copySourcesToOpl
   */
  static readonly CopySourcesToOplPath = '/opls/copy/sources';

  /**
   * Copy sources to the opl
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `copySourcesToOpl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copySourcesToOpl$Response(params: {
    context?: HttpContext
    body: OrderPreviewListCopySourcesDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.CopySourcesToOplPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Copy sources to the opl
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `copySourcesToOpl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  copySourcesToOpl(params: {
    context?: HttpContext
    body: OrderPreviewListCopySourcesDto
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.copySourcesToOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDefaultOpl
   */
  static readonly GetDefaultOplPath = '/opls/default';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultOpl$Response(params: {
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<OrderPreviewListDto>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.GetDefaultOplPath, 'get');
    if (params) {
      rb.query('recipientGuid', params.recipientGuid, {});
      rb.query('sourceType', params.sourceType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrderPreviewListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDefaultOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultOpl(params: {
    recipientGuid: string;
    sourceType: 'CORD' | 'DONOR' | 'BDP';
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<OrderPreviewListDto> {

    return this.getDefaultOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<OrderPreviewListDto>) => r.body as OrderPreviewListDto)
    );
  }

  /**
   * Path part for operation removeSourceFromOpl
   */
  static readonly RemoveSourceFromOplPath = '/opls/{id}/sources/{guid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeSourceFromOpl()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourceFromOpl$Response(params: {
    id: number;
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OrderPreviewListControllerService.RemoveSourceFromOplPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('guid', params.guid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeSourceFromOpl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeSourceFromOpl(params: {
    id: number;
    guid: string;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<void> {

    return this.removeSourceFromOpl$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
