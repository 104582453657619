/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { SsaRequestControllerService } from './services/ssa-request-controller.service';
import { OrderControllerService } from './services/order-controller.service';
import { OrderPreviewListControllerService } from './services/order-preview-list-controller.service';
import { SupplierControllerService } from './services/supplier-controller.service';
import { OrderableRuleControllerService } from './services/orderable-rule-controller.service';
import { OrderTypeControllerService } from './services/order-type-controller.service';
import { OrderTrackingControllerService } from './services/order-tracking-controller.service';
import { SecurityControllerService } from './services/security-controller.service';
import { NomenclaturesControllerService } from './services/nomenclatures-controller.service';
import { OrderImportantDatesControllerService } from './services/order-important-dates-controller.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SsaRequestControllerService,
    OrderControllerService,
    OrderPreviewListControllerService,
    SupplierControllerService,
    OrderableRuleControllerService,
    OrderTypeControllerService,
    OrderTrackingControllerService,
    SecurityControllerService,
    NomenclaturesControllerService,
    OrderImportantDatesControllerService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
