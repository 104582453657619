/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder, AvailableResponseType } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderTracking } from '../models/order-tracking';

@Injectable({
  providedIn: 'root',
})
export class OrderTrackingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getOrderTrackingByIds
   */
  static readonly GetOrderTrackingByIdsPath = '/order-trackings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds$Response(params: {
    depth?: number;
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, OrderTrackingControllerService.GetOrderTrackingByIdsPath, 'post');
    if (params) {
      rb.query('depth', params.depth, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: Array<OrderTracking>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds(params: {
    depth?: number;
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<{
[key: string]: Array<OrderTracking>;
}> {

    return this.getOrderTrackingByIds$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<OrderTracking>;
}>) => r.body as {
[key: string]: Array<OrderTracking>;
})
    );
  }

  /**
   * Path part for operation getOrderTrackingByIds1
   */
  static readonly GetOrderTrackingByIds1Path = '/order-trackings/no-history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIds1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds1$Response(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OrderTracking>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderTrackingControllerService.GetOrderTrackingByIds1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderTracking>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIds1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrderTrackingByIds1(params: {
    context?: HttpContext
    body: Array<number>
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OrderTracking>> {

    return this.getOrderTrackingByIds1$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OrderTracking>>) => r.body as Array<OrderTracking>)
    );
  }

  /**
   * Path part for operation getOrderTrackingByIdWithHistory
   */
  static readonly GetOrderTrackingByIdWithHistoryPath = '/order-trackings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderTrackingByIdWithHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderTrackingByIdWithHistory$Response(params: {
    id: number;
    depth?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<StrictHttpResponse<Array<OrderTracking>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderTrackingControllerService.GetOrderTrackingByIdWithHistoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('depth', params.depth, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
       ...{...requestOptions, ...(params?.context ? {context: params.context} : {})},
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrderTracking>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrderTrackingByIdWithHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderTrackingByIdWithHistory(params: {
    id: number;
    depth?: number;
    context?: HttpContext
  },
  requestOptions: {
    context?: HttpContext;
    setHeaders?: {
      [name: string]: string | string[];
    };
    responseType?: AvailableResponseType;
  } = {}
): Observable<Array<OrderTracking>> {

    return this.getOrderTrackingByIdWithHistory$Response(params, requestOptions).pipe(
      map((r: StrictHttpResponse<Array<OrderTracking>>) => r.body as Array<OrderTracking>)
    );
  }

}
